var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-page',{staticClass:"container text-center staticPage"},[_c('div',{staticClass:"row q-col-gutter-lg"},[_c('div',{staticClass:"col-12 col-md-6 offset-md-3"},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v("Customer Support")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.businessConfigurations.customerSupportDescription)}})])]),_c('div',{staticClass:"col-12 col-md-6 offset-md-3"},[_c('div',{staticClass:"row q-col-gutter-lg"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('a',{staticClass:"full-width s-1 radius-lg q-pa-md flex justify-center no-underline btn-border",attrs:{"href":`mailto:${_vm.businessConfigurations.customerServiceEmail}?subject=Customer Support`}},[_c('q-icon',{staticClass:"q-mr-md",attrs:{"size":"1.6rem","color":"secondary","name":"email"}}),_c('span',{staticClass:"text-dark text-body1"},[_vm._v(_vm._s(_vm.businessConfigurations.customerServiceEmail))])],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('a',{staticClass:"full-width s-1 radius-lg q-pa-md flex justify-center no-underline btn-border",attrs:{"href":`tel:${_vm.businessConfigurations.customerServicePhone}`}},[_c('q-icon',{staticClass:"q-mr-md",attrs:{"size":"1.6rem","color":"secondary","name":"phone"}}),_c('span',{staticClass:"text-dark text-body1"},[_vm._v(_vm._s(_vm.businessConfigurations.customerServicePhone))])],1)])])]),_c('div',{staticClass:"col-12 col-md-12"},[_vm._v(" ")]),_c('div',{staticClass:"col-12 col-md-12"},[_vm._v(" ")]),_c('div',{staticClass:"col-12 col-md-10 offset-md-1"},[_c('div',{staticClass:"row q-col-gutter-lg items-stretch justify-center"},[(
            _vm.businessConfigurations.enablePrivacyPolicy &&
            _vm.businessConfigurations.privacyPolicy
          )?_c('div',{staticClass:"col-12 col-md-4"},[_c('q-card',{staticClass:"full-height contentBox"},[_c('q-card-section',[_c('div',{staticClass:"absolute"},[_c('SvgIcon',{attrs:{"size":"2rem","icon":"privacy-policy"}})],1),_c('h4',{staticClass:"q-ma-none q-mb-md"},[_vm._v(" Privacy Policy ")]),_c('div',{staticClass:"q-ma-none q-mb-md ellipsis-2-lines",domProps:{"innerHTML":_vm._s(_vm.businessConfigurations.privacyPolicy)}}),_c('q-btn',{staticClass:"no-hover text-underline",attrs:{"unelevated":"","no-caps":"","flat":"","color":"secondary","size":"16px","label":"Read More","to":"/pages/legal/privacy-policy"}})],1)],1)],1):_vm._e(),(
            _vm.businessConfigurations.enableRefundPolicy &&
            _vm.businessConfigurations.refundPolicy
          )?_c('div',{staticClass:"col-12 col-md-4"},[_c('q-card',{staticClass:"full-height contentBox"},[_c('q-card-section',[_c('div',{staticClass:"absolute"},[_c('SvgIcon',{attrs:{"size":"2rem","icon":"refund-policy"}})],1),_c('h4',{staticClass:"q-ma-none q-mb-md"},[_vm._v(" Refund Policy ")]),_c('div',{staticClass:"q-ma-none q-mb-md ellipsis-2-lines",domProps:{"innerHTML":_vm._s(_vm.businessConfigurations.refundPolicy)}}),_c('q-btn',{staticClass:"no-hover text-underline",attrs:{"unelevated":"","no-caps":"","flat":"","color":"secondary","size":"16px","label":"Read More","to":"/pages/legal/refund-policy"}})],1)],1)],1):_vm._e(),(
            _vm.businessConfigurations.enableCustomerReviewPolicy &&
            _vm.businessConfigurations.customerReviewPolicy
          )?_c('div',{staticClass:"col-12 col-md-4"},[_c('q-card',{staticClass:"full-height contentBox"},[_c('q-card-section',[_c('div',{staticClass:"absolute"},[_c('SvgIcon',{attrs:{"size":"2rem","icon":"review-policy"}})],1),_c('h4',{staticClass:"q-ma-none q-mb-md"},[_vm._v(" Review Policy ")]),_c('div',{staticClass:"q-ma-none q-mb-md ellipsis-2-lines",domProps:{"innerHTML":_vm._s(_vm.businessConfigurations.customerReviewPolicy)}}),_c('q-btn',{staticClass:"no-hover text-underline",attrs:{"unelevated":"","no-caps":"","flat":"","color":"secondary","size":"16px","label":"Read More","to":"/pages/legal/review-policy"}})],1)],1)],1):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }