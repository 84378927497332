<template>
  <q-page class="container text-center staticPage">
    <div class="row q-col-gutter-lg">
      <div class="col-12 col-md-6 offset-md-3">
        <div class="title">
          <h1>Customer Support</h1>
          <p v-html="businessConfigurations.customerSupportDescription" />
        </div>
      </div>
      <div class="col-12 col-md-6 offset-md-3">
        <div class="row q-col-gutter-lg">
          <div class="col-12 col-md-6">
            <a
              :href="`mailto:${businessConfigurations.customerServiceEmail}?subject=Customer Support`"
              class="full-width s-1 radius-lg q-pa-md flex justify-center no-underline btn-border"
              ><q-icon
                size="1.6rem"
                color="secondary"
                name="email"
                class="q-mr-md"
              /><span class="text-dark text-body1">{{
                businessConfigurations.customerServiceEmail
              }}</span></a
            >
          </div>
          <div class="col-12 col-md-6">
            <a
              :href="`tel:${businessConfigurations.customerServicePhone}`"
              class="full-width s-1 radius-lg q-pa-md flex justify-center no-underline btn-border"
              ><q-icon
                size="1.6rem"
                color="secondary"
                name="phone"
                class="q-mr-md"
              /><span class="text-dark text-body1">{{
                businessConfigurations.customerServicePhone
              }}</span></a
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12">&nbsp;</div>
      <div class="col-12 col-md-12">&nbsp;</div>
      <div class="col-12 col-md-10 offset-md-1">
        <div class="row q-col-gutter-lg items-stretch justify-center">
          <div
            class="col-12 col-md-4"
            v-if="
              businessConfigurations.enablePrivacyPolicy &&
              businessConfigurations.privacyPolicy
            "
          >
            <q-card class="full-height contentBox">
              <q-card-section>
                <div class="absolute">
                  <SvgIcon size="2rem" icon="privacy-policy" />
                </div>
                <h4 class="q-ma-none q-mb-md"> Privacy Policy </h4>
                <div
                  class="q-ma-none q-mb-md ellipsis-2-lines"
                  v-html="businessConfigurations.privacyPolicy"
                />
                <q-btn
                  unelevated
                  no-caps
                  flat
                  color="secondary"
                  size="16px"
                  label="Read More"
                  class="no-hover text-underline"
                  to="/pages/legal/privacy-policy"
                />
              </q-card-section>
            </q-card>
          </div>
          <div
            class="col-12 col-md-4"
            v-if="
              businessConfigurations.enableRefundPolicy &&
              businessConfigurations.refundPolicy
            "
          >
            <q-card class="full-height contentBox">
              <q-card-section>
                <div class="absolute">
                  <SvgIcon size="2rem" icon="refund-policy" />
                </div>
                <h4 class="q-ma-none q-mb-md"> Refund Policy </h4>
                <div
                  class="q-ma-none q-mb-md ellipsis-2-lines"
                  v-html="businessConfigurations.refundPolicy"
                />
                <q-btn
                  unelevated
                  no-caps
                  flat
                  color="secondary"
                  size="16px"
                  label="Read More"
                  class="no-hover text-underline"
                  to="/pages/legal/refund-policy"
                />
              </q-card-section>
            </q-card>
          </div>
          <div
            class="col-12 col-md-4"
            v-if="
              businessConfigurations.enableCustomerReviewPolicy &&
              businessConfigurations.customerReviewPolicy
            "
          >
            <q-card class="full-height contentBox">
              <q-card-section>
                <div class="absolute">
                  <SvgIcon size="2rem" icon="review-policy" />
                </div>
                <h4 class="q-ma-none q-mb-md"> Review Policy </h4>
                <div
                  class="q-ma-none q-mb-md ellipsis-2-lines"
                  v-html="businessConfigurations.customerReviewPolicy"
                />
                <q-btn
                  unelevated
                  no-caps
                  flat
                  color="secondary"
                  size="16px"
                  label="Read More"
                  class="no-hover text-underline"
                  to="/pages/legal/review-policy"
                />
              </q-card-section>
            </q-card>
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
export default {
  name: 'PageCustomerService',
  meta() {
    return {
      title: 'Customer Support',
      ...this.commonMeta,
    }
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.staticPage {
  padding-top: 60px;
  padding-bottom: 60px;
  .title {
    margin-bottom: 1rem;
    h1 {
      margin: 0;
      margin-top: 1rem;
      padding: 0;
      font-size: 2.2rem;
      line-height: normal;
      color: #000;
    }
    p {
      margin: 0;
      margin-top: 1rem;
      padding: 0;
      font-size: 1.1rem;
      line-height: 1.4;
    }
  }
  .btn-border {
    border: 1px solid $secondary;
    border-color: var(--q-color-secondary);
  }
  .contentBox {
    border-radius: 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    .q-card__section {
      padding: 24px;
      padding-top: 60px;
      position: relative;
      .absolute {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        background: #fff;
        color: #7f7f7f;
        border: 1px solid #7f7f7f;
        top: 0;
        left: 50%;
        margin-top: -40px;
        margin-left: -40px;
        border-radius: 50%;
        box-shadow: 0 3px 5px rgba($textdark, 0.2);
      }
    }
    h4 {
      color: #5e5c5c;
      font-size: 24px;
      font-weight: bold;
      line-height: normal;
    }
    p {
      font-size: 18px;
      line-height: 1.4;
    }
    .ellipsis-2-lines {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      div {
        margin: 0 !important;
        padding: 0 !important;
        font-size: 16px !important;
        font-family: 'Lato', Arial, sans-serif !important;
        font-weight: normal !important;
        font-style: normal !important;
        line-height: normal !important;
      }
    }
  }
  @media (max-width: 1023px) {
    padding-top: 30px;
    padding-bottom: 0;
    .title {
      h1 {
        margin: 0;
        margin-top: 1rem;
        padding: 0;
        font-size: 1.6rem;
        line-height: normal;
      }
      p {
        margin: 0;
        margin-top: 1rem;
        padding: 0;
        font-size: 1rem;
        line-height: 1.4;
      }
    }
    .contentBox {
      margin-bottom: 30px;
      height: auto !important;
      h4 {
        font-size: 20px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}
</style>
